import React from "react"
import { graphql } from "gatsby"

import AwardeeDetail from "./awardee"


const CndaDetail = ({ data }) => (
  <AwardeeDetail name={data.cnda.name}
                 award={`${data.cnda.award} (${data.cnda.year})`}
                 address={data.cnda.address}
                 image={data.cnda.fields.imgPath}
                 description={data.cnda.description}
                 video={data.cnda.video_link} />
)


export const query = graphql`
  query($slug: String!) {
    cnda : cndaGeocodedCsv(fields: { slug: { eq: $slug } } ) {
      award
      year
      name
      address
      description
      video_link
      fields {
        imgPath {
          childImageSharp {
            fluid(maxWidth: 1140) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default CndaDetail
