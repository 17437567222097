import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Jumbotron from "react-bootstrap/Jumbotron"
import Container from "react-bootstrap/Container"
import Badge from "react-bootstrap/Badge"

import Layout from "../components/layout.js"
import SEO from "../components/seo"


const AwardeeDetail = props => (
  <Layout>
    <SEO title={props.name} />
    <Jumbotron style={{backgroundColor: "#d2ebec", padding: "1.5em"}}>
      <Container>
        <h1 style={{fontSize: '2em'}}>
          {props.name}
        </h1>
        <h4>
          <Badge variant="warning" id="award">
            <i className="fas fa-fw fa-trophy"></i>&nbsp;
            {props.award}
          </Badge>
        </h4>
        <hr />
        {props.architect ? <h2 style={{fontSize: '1.5em', fontWeight: '700'}}>{props.architect}</h2> : ''}
        <h3 style={{fontSize: '1.25em'}}>{props.address}</h3>
      </Container>
    </Jumbotron>
    <Container>
      <Link to="/#awardee-map">
        <p>
          <i className="fas fa-fw fa-arrow-left"></i>
          Back to map
        </p>
      </Link>
      <div className="awardee-detail-img">
        {props.image ? <Img fluid={props.image.childImageSharp.fluid} style={{marginBottom: "2rem", maxHeight: "500px"}} /> : ''}
      </div>
      <p>
        {props.description ? props.description : "No description was found for this awardee."}
      </p>

      {
        props.video
          ? <div className="embed-responsive embed-responsive-16by9" style={{margin: "2rem auto", maxHeight: "500px"}}>
              <iframe title={`Video for ${props.name}`} width="420" height="315" src={props.video}></iframe>
            </div>
          : ''
      }

      <br />
    </Container>
  </Layout>
)

export default AwardeeDetail
